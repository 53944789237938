import React, { useState, useEffect, useContext } from "react";
import { useIntl, Link } from "gatsby-plugin-react-intl";

import "./Styles/header.css";
import "./Styles/NavMenu.css";

export default function NavMenu() {
  const intl = useIntl();

  return (
    <nav className="categoryStructure">
      <div className="ab">
        <ul className="nav-icons-container cate">
          <li>
            <Link to={"/"}>
              {intl.formatMessage({ id: "static-s29" })}
            </Link>
          </li>
          <li className="navHeader">
            <span className="navDropHead">
              {intl.formatMessage({ id: "static-s30" })}{" "}
              <span className="material-icons arbtn">arrow_drop_down</span>
            </span>
            <ul className="subMenu">
              <li>
                <Link to={"/message-from-ceo"}>
                  {intl.formatMessage({ id: "static-s31" })}
                </Link>
              </li>
              <li>
                <Link to={"/maraton"}>
                  {intl.formatMessage({ id: "static-s32" })}
                </Link>
              </li>
              <li>
                <Link to={"/vision-and-mission"}>
                  {intl.formatMessage({ id: "static-s33" })}
                </Link>
              </li>
              <li>
                <Link to={"/our-values"}>
                  {intl.formatMessage({ id: "static-s34" })}
                </Link>
              </li>
              <li>
                <Link to={"/our-goals"}>
                  {intl.formatMessage({ id: "static-s35" })}
                </Link>
              </li>
              <li>
                <Link to={"/our-customers"}>
                  {intl.formatMessage({ id: "static-s36" })}
                </Link>
              </li>
              <li>
                <Link to={"/who-we-are"}>
                  {intl.formatMessage({ id: "static-s37" })}
                </Link>
              </li>
              <li>
                <Link to={"/our-advisory-board"}>
                  {intl.formatMessage({ id: "static-s38" })}
                </Link>
              </li>
              <li>
                <Link to={"/maraton-eco-system"}>
                  {intl.formatMessage({ id: "static-s39" })}
                </Link>
              </li>
            </ul>
          </li>
          <li className="navHeader">
            <span className="navDropHead">
              {intl.formatMessage({ id: "static-s40" })}{" "}
              <span className="material-icons arbtn">arrow_drop_down</span>
            </span>
            <ul className="subMenu">
              <li>
                <Link to={"/fair-and-event-services"}>
                  {intl.formatMessage({ id: "static-s41" })}
                </Link>
              </li>
              <li>
                <Link to={"/technology-services"}>
                  {intl.formatMessage({ id: "static-s42" })}
                </Link>
              </li>
              <li>
                <Link to={"/association-sanagement-services"}>
                  {intl.formatMessage({ id: "static-s43" })}
                </Link>
              </li>
              <li>
                <Link to={"/leadership-academy"}>
                  {intl.formatMessage({ id: "static-s44" })}
                </Link>
              </li>
              <li>
                <Link to={"/frequently-asked-questions"}>
                  {intl.formatMessage({ id: "static-s45" })}
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to={"/contact"}>
              {intl.formatMessage({ id: "static-s46" })}
            </Link>
          </li>
          <li>
            <Link to={"/blog"}>
              {intl.formatMessage({ id: "static-s47" })}
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
