import React, { useState } from "react";
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-react-intl";
import "./language.css";
const LanguageSelect = () => {
  const images = require.context("../../assets/icons/country", true);
  const [showLangBox, setShowLangBox] = useState(false);

  return (
    <div className="language-div">
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) => {
          return (
            <>
              <img
                className="language-flag-image"
                alt="Language Selector"
                src={images(
                  `./${currentLocale === "en" ? "ca" : currentLocale}.png`
                )}
                onClick={() => setShowLangBox(!showLangBox)}
              />
              <span className="material-icons arbtn1 mbn" onClick={() => setShowLangBox(!showLangBox)} style={{
                color : "#333"
              }}>arrow_drop_down</span>
              <div
                className="language-box"
                style={showLangBox ? { display: "flex" } : { display: "none" }}
              >
                {languages
                  .filter((l) => l !== currentLocale)
                  .map((language) => (
                    <a
                      key={language}
                      onClick={() => changeLocale(language)}
                      style={{
                        cursor: `pointer`,
                      }}
                    >
                      <div className="select-div">
                        <img
                          alt="Language Selector"
                          className="language-flag-image"
                          src={images(
                            `./${language === "en" ? "ca" : language}.png`
                          )}
                        />
                        
                      </div>
                    </a>
                  ))}
              </div>
            </>
          );
        }}
      </IntlContextConsumer>
    </div>
  );
};

export default LanguageSelect;
