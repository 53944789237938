import React, { useState, useEffect } from "react";

import { useLocation } from "@reach/router";

import { useIntl, Link } from "gatsby-plugin-react-intl";

import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
function Footer() {
  const intl = useIntl();
  const location = useLocation();
  const data = useStaticQuery(graphql`
    query {
      Logo: file(relativePath: { eq: "logoFoot.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      LogoEnterprise: file(relativePath: { eq: "logoFoot.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `); 
  return (
    <footer id="footer">
      <div className="new-footer" style={{ maxWidth: "100%" }}>
        <div className="columns " style={{ maxWidth: "100%" }}>
          <div className="footer-container">
          <div id="footerLinks">
              <p className="footer-copyright text-center">
                <a className="grey-text text-lighten-4" href="tel:+905458974445 ">
                  {intl.formatMessage({ id: "static-s27" })}
                </a>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <a
                  className="grey-text text-lighten-4"
                  href="mailto:info@maratonman.com"
                >
                  {intl.formatMessage({ id: "static-s28" })}
                </a> <br/><br/>
                {intl.formatMessage({ id: "footer-copyright" })}
              </p>
            </div>
            {/* <div id="footerTrademark" className="menu-footer">
              <p className="grey-text text-lighten-4 footer-copyright text-center">
                
                <p className="footer-copyright text-center">
                <Link className="grey-text text-lighten-4" to="/privacy-policy">
                  {intl.formatMessage({ id: "footer-privacy" })}
                </Link>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Link
                  className="grey-text text-lighten-4"
                  to="/cookies-policy"
                >
                  {intl.formatMessage({ id: "footer-cookies" })}
                </Link>
              </p>
              </p>
            </div> */}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
