import React, { useState, useContext, useEffect } from "react";
import { useIntl, Link } from "gatsby-plugin-react-intl";
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import { setHTMLElementFixedPosition } from "../functions/Utilities.js";
import $ from "jquery";
import NavMenu from "./NavMenu.jsx";
import { makeStyles } from '@material-ui/core/styles';
import LanguageSelect from "../shared/i18n/LanguageSelect";
import MaratonLogo from "../assets/img/Logo.jpg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func
};

if (typeof window !== "undefined") {
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 150) {
      $(".site-header").addClass("darkHeader");
    } else {
      $(".site-header").removeClass("darkHeader");
    }
  });
}

export default function MenuTopBar(props) {
  const intl = useIntl();
  const classes = useStyles();

  const [isOpened, setIsOpened] = useState(false);

  const toggle = () => {
    setIsOpened(wasOpened => !wasOpened);
  };

  const [isOpened1, setIsOpened1] = useState(false);

  const toggle1 = () => {
    setIsOpened1(wasOpened1 => !wasOpened1);
  };

  const [isMobileState, setIsMobileState] = useState(false);
  const [navMenuOpen, setNavMenuOpen] = useState(false);

  let initialMobile;
  if (typeof window !== "undefined") {
    initialMobile = window.innerWidth;
  }

  const [currentScreenWidth, setCurrentScreenWidth] = useState(initialMobile);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const handleResize = () => {
        setCurrentScreenWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  let isMobile;
  const mobileSize = 768;
  useEffect(() => {
    if (currentScreenWidth > mobileSize) {
      isMobile = false;
    } else {
      isMobile = true;
    }
    setIsMobileState(isMobile);
  }, [currentScreenWidth]);

  const handleMobileSubnav = (nav) => {
    var menu = document.getElementById(nav);
    menu.classList.toggle("open");
  };

  const toggleDrawer = () => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setNavMenuOpen(!navMenuOpen);
  };

  useEffect(() => {
    setHTMLElementFixedPosition(navMenuOpen);
    return () => {
      setHTMLElementFixedPosition(false);
    };
  }, [navMenuOpen]);

  return (
    <>
      <div className="positionAb">
        <React.Fragment>
          <CssBaseline />
          <HideOnScroll {...props}>
            <AppBar style={{
              boxShadow: "none"
            }}>
              <header id="header" className="site-header header-shop static sticky-js">
                <div className="container">
                  <nav
                    className="nav-extended"
                    style={{ background: "#fff", padding: "4px 0px" }}
                  >
                    <div className="nav-wrapper">
                      <div className="header-container" style={{ height: "auto" }}>
                        {isMobileState ? (
                          <React.Fragment>
                            <div className="icon-container">
                              <div className="icon-wrapper">
                                <i
                                  open={navMenuOpen}
                                  className="material-icons mobile-nav-icon"
                                  onClick={toggleDrawer()}
                                >
                                  <span></span>
                                </i>
                              </div>
                            </div>
                            <div className="mobile-nav-bar-container" open={navMenuOpen}>
                              <div
                                id="nav-menu-drawer"
                                className={"nav-wrapper actual-nav scroll-bar-thin-style"}
                              >
                                <div className="nav-content-wrapper">
                                  <div className="staticMenu">

                                    <div className="statihead">
                                      <div className="staticMOne"><Link to={"/"}>{intl.formatMessage({ id: "static-s29" })}</Link></div>
                                      <div className="staticMOne"><Link to={"/"}>{intl.formatMessage({ id: "static-s30" })}{" "}</Link> <span className="material-icons" style={{ float: "right" }} onClick={toggle}>arrow_drop_down</span></div>
                                      {isOpened && (
                                        <div className="statihead">
                                          <div className="staticMOne">
                                            <Link to={"/message-from-ceo"}>
                                              {intl.formatMessage({ id: "static-s31" })}
                                            </Link>
                                          </div>
                                          <div className="staticMOne">
                                            <Link to={"/maraton"}>
                                              {intl.formatMessage({ id: "static-s32" })}
                                            </Link>
                                          </div>
                                          <div className="staticMOne">
                                            <Link to={"/vision-and-mission"}>
                                              {intl.formatMessage({ id: "static-s33" })}
                                            </Link>
                                          </div>
                                          <div className="staticMOne">
                                            <Link to={"/our-values"}>
                                              {intl.formatMessage({ id: "static-s34" })}
                                            </Link>
                                          </div>
                                          <div className="staticMOne">
                                            <Link to={"/our-goals"}>
                                              {intl.formatMessage({ id: "static-s35" })}
                                            </Link>
                                          </div>
                                          <div className="staticMOne">
                                            <Link to={"/our-customers"}>
                                              {intl.formatMessage({ id: "static-s36" })}
                                            </Link>
                                          </div>
                                          <div className="staticMOne">
                                            <Link to={"/who-we-are"}>
                                              {intl.formatMessage({ id: "static-s37" })}
                                            </Link>
                                          </div>
                                          <div className="staticMOne">
                                            <Link to={"/our-advisory-board"}>
                                              {intl.formatMessage({ id: "static-s38" })}
                                            </Link>
                                          </div>
                                          <div className="staticMOne">
                                            <Link to={"/maraton-eco-system"}>
                                              {intl.formatMessage({ id: "static-s39" })}
                                            </Link>
                                          </div>
                                        </div>
                                      )}
                                      <div className="staticMOne"><Link to={"/"}>{intl.formatMessage({ id: "static-s40" })}{" "}</Link> <span className="material-icons" style={{ float: "right" }} onClick={toggle1}>arrow_drop_down</span></div>
                                      {isOpened1 && (
                                        <div className="statihead">
                                          <div className="staticMOne">
                                            <Link to={"/fair-and-event-services"}>
                                              {intl.formatMessage({ id: "static-s41" })}
                                            </Link>
                                          </div>
                                          <div className="staticMOne">
                                            <Link to={"/technology-services"}>
                                              {intl.formatMessage({ id: "static-s42" })}
                                            </Link>
                                          </div>
                                          <div className="staticMOne">
                                            <Link to={"/association-sanagement-services"}>
                                              {intl.formatMessage({ id: "static-s43" })}
                                            </Link>
                                          </div>
                                          <div className="staticMOne">
                                            <Link to={"/leadership-academy"}>
                                              {intl.formatMessage({ id: "static-s44" })}
                                            </Link>
                                          </div>
                                          <div className="staticMOne">
                                            <Link to={"/frequently-asked-questions"}>
                                              {intl.formatMessage({ id: "static-s45" })}
                                            </Link>
                                          </div>
                                        </div>
                                      )}
                                      <div className="staticMOne"><Link to={"/contact"}>{intl.formatMessage({ id: "static-s46" })}</Link></div>
                                      <div className="staticMOne"><Link to={"/blog"}>{intl.formatMessage({ id: "static-s47" })}</Link></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ) : null}

                        <div className="logo-container">
                          <Link to={"/"} className="brand-logo logo-i">
                            <img
                              src={MaratonLogo}
                              alt="Maraton Logo"
                              className="img-responsive"
                              height="48px"
                              style={isMobileState ? { marginTop: "5px" } : { height: "48px" }}
                            />
                          </Link>
                        </div>
                        {!isMobileState ?
                          <NavMenu /> : null}

                        <div className="nav-icons-container">
                          <div className="nav-icons-container">
                            <div className="icon-container">
                              <LanguageSelect />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>



                </div>
              </header>

              {/* <div className="menuInfo">
                <div className="header-container">
                  <NavMenu />
                </div>
              </div> */}

            </AppBar>
          </HideOnScroll>
          <Toolbar />
        </React.Fragment>
      </div>



    </>
  );
}
