import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { IntlProvider } from "react-intl";
import { useLocation } from "@reach/router";
import MenuTopBar from "../header/MenuTopBar";

import Footer from "../components/AC-Footer/Footer";

import "../assets/css/googleFonts.css";
import "../assets/css/menu.css";
import "../assets/css/minified.css";
import "../assets/css/avetti.css";
import "../assets/css/mailchimp.css";

import "../assets/css/sl.css";
import "../assets/css/b2b2c.css";
import "../assets/css/fontello.css";
import "../assets/jss/demo-ar.scss";

import { Helmet } from "react-helmet";
//import CookieDialog from "../components/AC-Cookie/CookieDialog";

const Layout = ({ children }) => {
  const location = useLocation();

  return (
    <IntlProvider locale={"en"}>      
      <MenuTopBar />       
      {children}
      <Footer />
    </IntlProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
